import React from 'react'
import Link from 'gatsby-link'
import Layout from '../components/indexLayout'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'


const IndexPage = props => (
  <Layout>
    <div className="page white-bg">
      <div className="container">
        <section className="hero-section">
          <div className="hero-texts">
            <Img
              fluid={props.data.imageFirst.childImageSharp.fluid}
              className="sosc-logo"
              alt="sosc logo"
            />
            {/* <p className="sosc-header">
              呉島道場
              <br />
              ガチ系プログラマ育成塾
            </p> */}
            <p className="sosc-header2">
              「呉島道場」ってどんなところ？
            </p>
            <div className="dash" />
            <ol style={{ listStyleType: 'decimal' }}>
              <li>
                ホームページや、アプリを作る技術を学ぶところ
              </li>
              <li>
                広島市中区大手町に教室があります。
              </li>
              <li>
                社会人や主婦など、大人に特化したスクール
              </li>
              <li>
                教科書内容にとらわれず、個人レベルに合わせ、進める。
              </li>
              <li>
                わからないことはいつでも質問できる。
              </li>
            </ol>
            <p>
              今、学ばず、いつまなぶのか？我々は広島で、一人でも多くのプログラマ・エンジニアの育成に務める道場です。
            </p>
            <a
              href="https://goo.gl/forms/XVRdfeiEYTiHwx0A3"
              className="btn-hero-green"
              target="_blank"
            >
              塾長に聞いてみる
            </a>
          </div>
          <div>
            <Img
              fluid={props.data.imageOne.childImageSharp.fluid}
              className="hero-image"
              alt="hero image"
            />
          </div>
        </section>
      </div>
      <div className="container">
        <section className="hero-section2 ">
          <Img
            fluid={props.data.imageTwo.childImageSharp.fluid}
            className="hero-image"
            alt="hero image"
          />
          <div className="hero-texts">
            <p className="hero-header">即戦力のプログラマ・エンジニアへの最短の道</p>
            <div className="dash" />
            <p>
              今では農業でもファッションでもゲームでもどの分野にもプログラマが必要な状態です。Webサイト作成や経理の効率化などプログラミングで解決できる問題は、世の中にたくさん残っていますがプログラマが不足しているのが現状です。そしてIT企業だけではなく多くの業種でプログラマを積極的に採用する動きが活発化しています。
            </p>
            <p>
              技術発展が目覚ましい業界では若いうちから現場で経験を積む事が重要。呉島道場では、短期間でプログラマとしての基礎から考え方までを習得し、いち早く業界で活躍できるような即戦力人材を育成します。
            </p>
            <p>
              特に女性のIT技術者育成に力を入れております。
            </p>
            <a
              href="https://goo.gl/forms/XVRdfeiEYTiHwx0A3"
              className="btn-hero-green"
              target="_blank"
            >
              塾長に聞いてみる
            </a>
          </div>
        </section>
      </div>

      <div className="container">
        <section className="hero-section3">
          <div className="hero-texts">
            <p className="hero-header">「呉島道場」が他のスクールとちがうところ</p>
            <div className="dash" />
            <p>
              広島市で現役のプロエンジニアが直接教えます。一般的な「プログラミング」だけを教えるのではなく、開発環境やリリース、就職・転職までしっかりサポートします。
            </p>
            <p>
              今流行りの「オンラインスクール」となにが違うんですか？と聞かれます。オンラインスクールのメリットは、「学びたい時間に学べる」とか「時間割に合わせる必要がない」ということがよく掲げられます。
            </p>
            <p>
              しかし、よく考えてみてください。<br />
              本気で学ぶならやはり「先生と生徒が面と向かって学ぶ」べきだと思いませんか。
              質問したいときに質問できる環境が必要ではないでしょうか。
            </p>
            <p>
              呉島道場のメリットは
            </p>
            <ol style={{ listStyleType: 'decimal' }}>
              <li>
                時間割が決まっているため、学びのチャンスを先延ばしにしない。自分を追い込むことが大切。
              </li>
              <li>
                生徒の状況に応じて、進捗を変えたり内容を変えたりできる。
              </li>
              <li>
                先生に直接質問ができる。
              </li>
              <li>
                プログラムを教えるだけではなく、リリース・運用まで教わることができる。
              </li>
              <li>
                生徒同士、学ぶ仲間が増える。コミュニティーの形成される。
              </li>
            </ol>
            <p>
              呉島道場では、生徒と先生が、ガチで向き合ってスキルアップを計る道場です。
            </p>
            <a
              href="https://goo.gl/forms/XVRdfeiEYTiHwx0A3"
              className="btn-hero-green"
              target="_blank"
            >
              塾長に聞いてみる
            </a>
          </div>
          <Img
            fluid={props.data.imageThree.childImageSharp.fluid}
            className="hero-image"
            alt="hero image"
          />
        </section>
      </div>
      <div className="container">
        <section className="hero-section4">
          <div>
            <Img
              fluid={props.data.imageFour.childImageSharp.fluid}
              className="hero-image"
              alt="hero image"
            />
          </div>
          <div className="hero-texts">
            <p className="hero-header">目指せ「IT女子！」</p>
            <div className="dash" />
            <p>
              昨年から開始した「呉島道場」では、未経験の特に「IT女子」を目指す、社会人女性の方々との沢山の学びを深めました。
              年齢・性別は不問ですが、基本的に社会人の方を対象としております。
              参加条件は、やる気がある方、広島在住の方の２つだけです。
            </p>
            <p>
              来られた方のレベルに合わせ、カリキュラムを作る、これが「呉島道場」の強みです。
              教科書通りではない、深い学びを共に分かち合いましょう。
            </p>
            <p>
              あなたは、第一歩を踏み出せばいい。それだけです。
            </p>
            <a
              href="https://goo.gl/forms/XVRdfeiEYTiHwx0A3"
              className="btn-hero-green"
              target="_blank"
            >
              塾長に聞いてみる
            </a>
          </div>
        </section>
      </div>
    </div>
  </Layout>
)

export const firstSiteImages = graphql`
  fragment firstSiteImages on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    imageFirst: file(relativePath: { eq: "images/logo_grad.png" }) {
      ...firstSiteImages
    }
    imageOne: file(relativePath: { eq: "images/hero_main.png" }) {
      ...firstSiteImages
    }
    imageTwo: file(relativePath: { eq: "images/hero_clubs.png" }) {
      ...firstSiteImages
    }
    imageThree: file(relativePath: { eq: "images/hero_github.png" }) {
      ...firstSiteImages
    }
    imageFour: file(relativePath: { eq: "images/hero_connect_.png" }) {
      ...firstSiteImages
    }
  }
`

export default IndexPage
